.my-top {
    height: 20rem;
    background: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.header-img {
    border-radius: 90px;
    border: #ddd 1px solid;
    overflow: hidden;
    box-shadow: #ccc 1px 1px;
}

.menu-list {
    background: #fff;
    padding: 0.8rem 1rem;
    border-bottom: #ccc solid 0.1rem;
}